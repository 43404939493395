function NoToChinese(num) {
    if (!/^\d*(\.\d*)?$/.test(num)) {
        alert("Number is wrong!");
    }else {
        var result='';
        switch (num)
        {
            case 1:
                result='一';
                break;
            case 2:
                result='二';
                break;
            case 3:
                result='三';
                break;
            case 4:
                result='四';
                break;
            case 5:
                result='五';
                break;
            case 6:
                result='六';
                break;
            case 7:
                result='七';
                break;
            case 8:
                result='八';
                break;
            case 9:
               result='九';
                break;
        }
        return  result;
    }
}